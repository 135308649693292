<template>
  <div
    :class="[
      'cart-actions',
      { 'without-after' : categories && categories.length === 0 }
    ]"
  >
    <div
      class="cart-actions-barcode"
      v-on:click="openNumpad"
      v-on:touchstart="touchStart($event)"
      v-on:touchend="touchEnd($event);"
      v-on:touchcancel="touchEnd($event)"
    >
      <span v-html="$t('terminal.cart.barcode.headline')"></span>
    </div>
    <div class="cart-actions-inner">
      <!-- cart:actions:categories START -->
      <div
        :class="[
          'categories',
          { 'scroll' : categories && categories.length > 3 }
        ]"
        v-if="categories && categories.length > 0"
      >
        <div class="categories-inner">
          <div class="headline" v-html="$t('terminal.cart.categories.headline')"></div>
          <div class="buttons">
            <div
              class="btn-default-grey btn-category"
              v-for="(category, categoryIndex) in categories"
              :key="`categories-category-${categoryIndex}`"
              v-html="category.name"
              v-on:click="activeCategory(category.id, category.name); dialogOpen()"
              v-on:touchstart="touchStart($event)"
              v-on:touchend="touchEnd($event);"
              v-on:touchcancel="touchEnd($event)"
            ></div>
          </div>
        </div>
      </div>
      <!-- cart:actions:categories END -->

      <!-- cart:actions:pay START -->
      <div class="pay">
        <div class="pay-inner">
          <h3 class="total-headline" v-html="$t('terminal.cart.total.headline')"></h3>

          <!-- cart:actions:checkout:total START -->
          <div class="total">
            <transition name="scale" mode="out-in">
              <div :key="total" class="total-number scale-animation">
                <span
                  v-if="showPriceFormat"
                  v-html="`${products.currencySymbol} ${priceFormat(total)}`"
                ></span>
              </div>
            </transition>
          </div>
          <!-- cart:actions:checkout:total END -->

          <!-- cart:actions:checkout:done START -->
          <transition name="opacity" mode="out-in">
            <div class="done">
              <button
                class="btn-default btn-done"
                :disabled="products.items && products.items.length === 0"
                v-html="`${$t('terminal.cart.total.donenew')}`"
                v-on:click="done()"
                v-on:touchstart="touchStart($event)"
                v-on:touchend="touchEnd($event);"
                v-on:touchcancel="touchEnd($event)"
              ></button>
            </div>
          </transition>
          <!-- cart:actions:checkout:done END -->

          <!-- cart:actions:checkout:abort START -->
          <div
            class="abort"
            v-on:click="resetStoreCart()"
          >
            <span v-html="$t('terminal.cart.total.abort')"></span>
          </div>
          <!-- cart:actions:checkout:abort END -->
        </div>
      </div>
      <!-- cart:actions:pay END -->
    </div>

    <dialog-numpad
      :dialogNumpadVisible="dialogNumpadVisible"
      :closeNumpad="closeNumpad"
    />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';

export default {
  name: 'cart-actions',
  props: {
    products: {
      type: Object,
    },

    total: {
      type: Number,
    },

    categories: {
      type: Array,
    },

    priceFormat: {
      type: Function,
    },

    resetStoreCart: {
      type: Function,
    },

    activeCategory: {
      type: Function,
    },

    dialogOpen: {
      type: Function,
    },

    addProduct: {
      type: Function,
    },

    isBarcodeActive: {
      type: Function,
    },
    done: {
      type: Function,
    },
  },
  components: {
    DialogNumpad: () => import('../shared/dialog/Numpad.vue'),
  },
  data() {
    return {
      /**
       * Dialog
       */
      dialogNumpadVisible: false,
      showPriceFormat: false,
    };
  },
  async created() {
    // fetch payment categories
    await this.setPaymentCategories();

    // wait for sum loaded
    if (this.priceFormat(this.total) !== 'undefined NaN') {
      setTimeout(() => {
        this.showPriceFormat = true;
      }, 350);
    }
  },
  computed: {
    ...mapGetters({
      paymentCategories: 'paymentCategories',
    }),
  },
  methods: {
    ...mapActions([
      'setPaymentCategories',
    ]),

    openNumpad() {
      this.dialogNumpadVisible = true;
      this.isBarcodeActive();
    },
    closeNumpad(ean) {
      if (ean) {
        this.dialogNumpadVisible = false;
        this.addProduct(Number(ean));
        this.isBarcodeActive();
      } else {
        this.dialogNumpadVisible = false;
        this.isBarcodeActive();
      }
    },

    touchStart(event) {
      event.target.classList.add('pressed');
    },

    touchEnd(event) {
      event.target.classList.remove('pressed');
    },
  },
};
</script>
